/**
 * ----------------------------------------
 * Colors
 * ----------------------------------------
 */

// black
$black: #181829;

// blue
$blue: #0057b7;
$light-blue: #3697ff;

// gray
$gray: #e5e5e5;

// green
$green: #20ca47;
$dark-green: #1ba03a;

// red
$red: #eb5757;
$dark-red: #c13232;

// white
$white: #ffffff;

// theme colors
$background: #f7f7fc;
$auth-background: #0d1031;
$primary: #080875;
$primary-transparent: #0808750f;
$btn-blue: #1f96ff;
$line: #eaeaea;
$low-opacity-text: #8f8f96;
$lower-opacity-text: #c5c5ce77;
$tooltip-bg: #555;
$login-bg: #0d1031;

// temperature colors
$very-cold: #0245ff;
$cold: #05abc3;
$cool: #a2d168;
$warm: #ffdf00;
$hot: #fc7908;
$very-hot: #f40701;

/**
 * ----------------------------------------
 * Fonts
 * ----------------------------------------
 */

$dm-sans: "DM Sans";
$sans-serif: "sans-serif";

/**
 * ----------------------------------------
 * Breakpoints
 * ----------------------------------------Â
 */

$phone-min: 0;
$phone-max: 599;

$tablet-min: 600;
$tablet-max: 767;

$laptop-min: 768;
$laptop-max: 1339;

$desktop-min: 1340;
