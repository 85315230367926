@import "./theme/core.scss";

.main {
  width: 100%;
  height: 100%;
  display: grid;
  background-color: $background;
  .content {
    background-color: $background;
    grid-area: mainContent;
    height: fit-content;
    width: calc(100vw - 240px);
    // overflow-x: hidden;
    @include tablet-or-less {
      width: 100vw;
    }
  }
  grid-template-columns: 240px auto;
  grid-template-rows: 80px auto;
  grid-template-areas:
    "navBar mainBar"
    "navBar mainContent";

  @include tablet-or-less {
    width: 100vw;
    grid-template-columns: 100%;
    grid-template-rows: 50px auto;
    grid-template-areas:
      "mainBar"
      "mainContent";
  }
}
